@charset "utf-8";
@import "~normalize.scss/normalize";

@import "~aos/dist/aos";
body main .aos-init[data-aos] {
  pointer-events: auto;
  transition: transform 0.8s, opacity 0.8s 0.4s;
}

@import "structure/structure";

@import "_base";
@import "shared/modules";
@import "apps/layout";
@import "apps/header";
@import "apps/footer";
@import "apps/gift";
@import "apps/shop";
@import "apps/index";
@import "apps/specialty";
@import "apps/maintenance";
@import "apps/recruit";
