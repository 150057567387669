.recruit {
  $gray: #dbd8d2;
  $white-dark: #eae8e4;
  $white: #f7f6f5;
  background: rgba($white, 0.1) url(../images/recruit/bg.svg) repeat;
  .g-nav {
    background: none;
    .mark {
      a {
        display: block;
        background-color: rgba(#fff, 0.4);
        margin: 0 auto;
        mask: url(../images/mark.svg) no-repeat center / contain;
        width: 52px;
      }
      img {
        opacity: 0;
      }
    }
  }
  .routate {
    opacity: 0.143;

    background-color: #fff;
    margin: 0 auto;
    mask: url(../images/mark.svg) no-repeat center / contain;
    img {
      opacity: 0;
    }
  }
  .container {
    background: none;
  }
  &Head {
    display: flex;
    .img {
      flex: 0 1 55%;
      min-width: 0;
      img {
        display: block;
        max-width: 100%;
      }
    }
    .title {
      display: flex;
      flex: 1;
      h1 {
        order: 1;
        font-size: 50px;
        padding: 35px 0 0 50px;
        position: relative;
        b {
          letter-spacing: 0.06em;
          position: relative;
          writing-mode: vertical-rl;
          z-index: 1;
        }
        span {
          color: $gray;
          font-size: 90px;
          display: block;
          position: absolute;
          transform: rotate(90deg);
          transform-origin: top left;
          white-space: nowrap;
          top: 0;
          left: 110px;
        }
      }
      h3 {
        align-items: center;
        display: flex;
        flex: 1;
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 0.08em;
        line-height: 1.8;
        margin-top: 200px;
        order: 0;
        writing-mode: vertical-rl;
        white-space: nowrap;
        text-shadow: darken($gray, 30%) 1px 1px 4px;
        @media all and (max-width: 980px) {
          margin-top: 80px;
          font-size: 32px;
        }
      }
    }
  }
  &Main {
    display: flex;
    .img {
      flex: 0 1 37%;
      min-width: 0;
      img {
        display: block;
        max-width: 100%;
      }
    }
    .text {
      font-size: 15px;
      line-height: 1.8;
      flex: 1;
      margin-top: -40px;
      padding-right: 96px;
      @media all and (max-width: 980px) {
        padding-right: 40px;
      }
      span {
        padding-right: 64px;
        display: block;
        @media all and (max-width: 980px) {
          padding-right: 0;
        }
      }
      img {
        display: block;
        margin: 64px 0 0 auto;
      }
    }
  }
  .information {
    background: #fff;
    margin: 80px auto;
    max-width: 740px;
    &List {
      font-size: 14px;
      li + li {
        margin-top: 40px;
      }
      h4 {
        font-size: 16px;
      }
      dl {
        border: 1px solid $white-dark;
        display: flex;
        line-height: 22px;
        flex-wrap: wrap;
        margin-top: 16px;
        dt,
        dd {
          border-bottom: 1px solid $white-dark;
          padding: 8px 24px;
        }
        dt {
          background: $white;
          border-right: 1px solid $white-dark;
          width: 140px;
          &:nth-last-child(2) {
            border-bottom: 0;
          }
        }
        dd {
          font-size: 13px;
          width: calc(100% - 140px);
          &:last-child {
            border-bottom: 0;
          }
        }
      }
      &Status {
        border-bottom: 1px solid $white-dark;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        h4 {
          width: 164px;
        }
      }
      &Example {
        background: $white;
        font-size: 11px;
        line-height: 1.6;
        padding: 10px 16px;
        margin-top: 8px;
      }
    }
    &Contact {
      margin-top: 80px;
      text-align: center;
      p {
        font-size: 15px;
        letter-spacing: 0.04em;
        padding-left: 0.04em;
        margin-bottom: 16px;
      }
      &Tel {
        font-size: 50px;
      }
      span {
        display: block;
        margin: 8px 0 18px;
      }
      .button {
        align-items: center;
        background: #ae3420;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(#000, 0.3);
        color: #fff;
        letter-spacing: 0.1em;
        padding: 12px 24px;
        transition: 0.2s;
        display: inline-flex;
        i {
          border: 1px solid #fff;
          border-radius: 50%;
          display: block;
          margin: 0 0 2px 10px;
          padding: 5px 0 0 4px;
          height: 18px;
          width: 18px;
          &:after {
            border: 1px solid transparent;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            box-sizing: content-box;
            display: block;
            content: "";
            height: 4px;
            transform: rotate(45deg);
            width: 4px;
          }
        }
        @media (hover: hover) {
          &:hover {
            box-shadow: 0 0 6px rgba(#000, 0);
            background: darken(#ae3420, 6%);
          }
        }
      }
    }
    &Sns {
      margin: 40px 0;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        background: darken($black, 80%);
        display: block;
        margin: 0 16px;
        height: 37px;
        width: 37px;
        @media (hover: hover) {
          img {
            transition: 0.3s;
          }
          &:hover {
            img {
              opacity: 0;
            }
          }
        }
        &.insta {
          mask: url(../images/recruit/icon-instagram.svg) no-repeat center /
            contain;
          background: linear-gradient(
            45deg,
            #f09433 0%,
            #e6683c 25%,
            #dc2743 50%,
            #cc2366 75%,
            #bc1888 100%
          );
        }
        &.fb {
          mask: url(../images/recruit/icon-facebook.svg) no-repeat center /
            contain;
          background: #1877f2;
        }
      }
    }
  }
}
