header {
  position: relative;
  z-index: 1;
}
footer {
  position: relative;
  z-index: 2;
}
.container {
  background: #fff;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.routate {
  opacity: 0.2;
  display: block;
  position: absolute;
  right: -320px;
  top: 80px;
  z-index: 1;
  width: 800px;
}
.content {
  padding: 16px 0 40px;
  max-width: 1184px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  section {
    padding: 40px 80px;
  }
  .note {
    color: $black-light;
    line-height: 1.6;
    font-size: 11px;
  }
  h2,
  .h2 {
    font-size: 24px;
    font-weight: bold;
  }
  h3,
  .h3 {
    font-size: 22px;
  }
  h4,
  .h4 {
    font-size: 20px;
  }
}

section.left-image,
section.right-image {
  display: table;
  table-layout: fixed;
  & > * {
    display: table-cell;
    vertical-align: middle;
  }
  .image {
    width: 55%;
    &.en {
      width: 50%;
    }
  }
  .quarter {
    @include clearfix;
    li {
      float: left;
      padding: 0 8px 16px 0;
      width: 50%;
      &:nth-child(2n) {
        padding: 0 0 16px 8px;
      }
      &:nth-child(3),
      &:nth-child(4) {
        padding-bottom: 0;
      }
    }
  }
  img {
    display: block;
    width: 100%;
  }
  .cnt {
    line-height: 2;
    font-size: 16px;
    vertical-align: middle;
    width: 45%;
    @media all and (max-width: 980px) {
      font-size: 15px;
    }
    &.en {
      line-height: 1.6;
      font-size: 14px;
      width: 50%;
      h2,
      .h2 {
        font-weight: normal;
      }
      .note {
        font-size: 12px;
        padding-top: 16px;
      }
    }
    &.center {
      text-align: center;
    }
    .inner {
      display: inline-block;
      line-height: 1.3;
      text-align: left;
      & > * {
        padding-bottom: 16px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  ul span {
    display: inline-block;
    width: 5em;
  }
  .menu {
    line-height: 2;
    li {
      & + li {
        margin-top: 24px;
      }
      div {
        // display: flex;
        font-weight: normal;
        padding-left: 2.2em;
        position: relative;
        &:before {
          border: 0.5px solid #888;
          content: "";
          display: block;
          top: 16px;
          left: 1em;
          position: absolute;
          width: 1em;
        }
      }
    }
    &.dinner {
      li div {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
    span {
      display: block;
      flex: 1;
      line-height: 1;
      text-align: right;
      min-width: 16em;
      width: 100%;
    }
    &.en span {
      width: 20em;
      .note {
        font-size: 11px;
        padding: 0;
        line-height: 1.2;
      }
    }
  }
  h2 {
    margin-bottom: 16px;
  }
}

section.left-image .cnt {
  padding-left: 16px;
  &.en {
    padding-left: 40px;
  }
}
section.right-image .cnt {
  padding-right: 16px;
  &.en {
    padding-right: 40px;
  }
}

section.over-image {
  position: relative;
  .img {
    position: relative;
    z-index: 1;
  }
  .cnt {
    padding-top: 16px;
    position: absolute;
    text-align: left;
    top: 40px;
    z-index: 2;
  }
  &.left-image .cnt {
    right: 80px;
  }
  &.right-image .cnt {
    left: 80px;
  }
}
.message {
  font-size: 16px;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 640px;
  padding: 40px 0 0 !important;
  h2 {
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
  }
  small {
    display: block;
    font-size: 16px;
    text-align: right;
    padding-top: 8px;
  }
  ul {
    font-size: 15px;
    margin: 2em 0;
    li + li {
      margin-top: 8px;
    }
  }
  span,
  table {
    display: block;
    padding-left: 3em;
  }
  table {
    display: block;
    padding-left: 3em;
    td + td {
      padding-left: 8px;
    }
  }
  p + p {
    margin-top: 4em;
  }
}

h1#pageTitle {
  line-height: 1;
  font-size: 28px;
  margin: 0 auto 80px;
  text-align: center;
  width: 28px;
  &.en {
    font-family: $enheadfont-family;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 8px;
    margin: 136px 0 56px;
    width: auto;
  }
}
