.disnon {
  display: none !important;
}

html,
body {
  -moz-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "pkna" 1;
  color: darken($black, 80%);
  font-family: $seriffont-family;
  font-size: 14px;
  line-height: 1.3;
  min-height: 100%;
  min-width: 840px;
  width: 100%;
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
* {
  word-wrap: break-word;
  word-break: break-all;
}
a {
  transition: all 0.2s;
  color: $black-light;
  cursor: pointer;
  text-decoration: none;
}
a.underline {
  text-decoration: underline;
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
}
a:link {
  color: $black-light;
}
@media (hover: hover) {
  a:hover {
    text-decoration: none;
    color: lighten($black-light, 30%);
  }
}

.en {
  font-family: $enfont-family;
}
.table {
  display: table;
  table-layout: fixed;
  width: 100%;
  & > * {
    display: table-cell;
    vertical-align: middle;
  }
}
