.foot-reservation {
  position: fixed;
  //font-family: $basefont-family;
  right: 0;
  top: 120px;
  z-index: 4;
  b {
    display: inline-block;
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 1.2em;
  }
  a {
    background: #ae3420;
    border-radius: 6px 0 0 6px;
    box-shadow: 0 3px 6px rgba(#000, 0.3);
    color: #fff !important;
    display: block;
    line-height: 1.1;
    font-size: 13px;
    padding: 16px 12px 12px;
    text-align: center;
    width: 42px;
    @media (hover: hover) {
      &:hover {
        background: darken(#ae3420, 6%);
      }
    }
    & + a {
      margin-top: 24px;
    }
  }
  span {
    display: inline-block;
  }
  i {
    border: 1px solid #fff;
    border-radius: 50%;
    display: block;
    margin-top: 4px;
    padding: 5px 0 0 4px;
    height: 18px;
    width: 18px;
    &:after {
      border: 1px solid transparent;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      box-sizing: content-box;
      display: block;
      content: "";
      height: 4px;
      transform: rotate(45deg);
      width: 4px;
    }
  }
  &.en {
    bottom: 120px;
    font-size: 11px;
    padding: 8px 3px 8px 6px;
    top: auto;
    width: 110px;
    span {
      line-height: 18px;
      vertical-align: top;
    }
    i {
      display: inline-block;
      margin: 0 0 0 8px;
    }
  }
}
footer {
  .facebook {
    background: rgb(240, 242, 245);
    position: fixed;
    bottom: -296px;
    left: 24px;
    padding: 8px;
    transition: 0.4s;
    height: 296px;
    z-index: 100;
    label {
      align-items: center;
      color: rgb(240, 242, 245);
      border-radius: 6px 6px 0 0;
      background: #385898;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      left: 0;
      padding: 8px 16px 6px;
      position: absolute;
      height: 32px;
      top: -32px;
      z-index: -1;
      i {
        align-items: center;
        // border: 1px solid #fff;
        // border-radius: 50%;
        display: flex;
        justify-content: center;
        transform: rotate(-45deg);
        transition: 0.4s;
        margin-left: 8px;
        // height: 18px;
        // width: 18px;
        &:after {
          border: 1px solid transparent;
          border-top: 1px solid #fff;
          border-right: 1px solid #fff;
          box-sizing: content-box;
          display: block;
          content: "";
          margin: 2px 2px 0 0;
          height: 4px;
          width: 4px;
        }
      }
    }
    &-toggle {
      &:checked + .facebook {
        bottom: 0;
        label i {
          transform: rotate(135deg);
        }
      }
    }
  }
  .inner {
    @include clearfix;
    background: $grunge_background left top repeat;
    padding: 24px 80px;
    position: relative;
    @media all and (max-width: 980px) {
      padding: 24px 48px;
    }
  }
  h1 {
    float: left;
    img {
      display: block;
      height: 120px;
    }
  }
  .address {
    float: left;
    padding: 40px 0 0 24px;
  }
  .tel {
    display: block;
    font-size: 32px;
    line-height: 1;
    padding-top: 8px;
  }
  .f-nav {
    float: right;
    ul {
      @include clearfix;
    }
    li {
      float: right;
      margin-left: 32px;
    }
    a {
      display: block;
      font-size: 20px;
      font-weight: bold;
      line-height: 1;
      padding-left: 10px;
      position: relative;
      text-align: right;
      width: 32px;
      span {
        transform: rotate(90deg);
        transform-origin: left top;
        display: block;
        color: $gray;
        font-size: 14px;
        left: 12px;
        line-height: 1;
        position: absolute;
        text-align: left;
        top: 0;
        width: 8em;
      }
    }
  }
  .copy {
    color: $gray;
    font-family: $basefont-family;
    font-size: 11px;
    text-align: right;
    padding: 8px;
  }
  &.en {
    .tel {
      font-size: 24px;
    }
    .address {
      padding-right: 0;
    }
    .f-nav {
      padding-top: 24px;
      padding-right: 100px;
      li {
        float: left;
        margin: 0;
      }
      a {
        font-size: 13px;
        letter-spacing: 2px;
        height: auto;
        padding: 0 0 0 24px;
        text-align: center;
        width: auto;
      }
    }
    .copy {
      text-align: right;
      padding-top: 48px;
    }
  }
}
