#shop {
  scroll-behavior: smooth;
  .container {
    padding-bottom: 0;
    overflow: clip;
    .caution {
      div {
        background: rgba($gray, 0.1);
        border: 1px solid $gray;
        border-radius: 8px;
        color: $black-light;
        font-family: $basefont-family;
        font-size: 15px;
        line-height: 1.6;
        margin: 0 auto;
        max-width: 640px;
        padding: 24px;
        h2 {
          font-size: 18px;
          text-align: center;
          padding-bottom: 8px;
        }
        ul {
          li {
            padding-left: 1em;
            position: relative;
            &:before {
              content: "・";
              left: 0;
              position: absolute;
            }
            & + li {
              margin-top: 8px;
            }
          }
        }
        small {
          display: block;
          font-size: 13px;
          padding-top: 8px;
        }
      }
    }
    .map {
      position: relative;
      z-index: 2;
      .overlay {
        background-color: rgba(0, 0, 0, 0);
      }
      iframe {
        display: block;
        width: 100%;
      }
    }
  }
  .section {
    padding: 80px 0 40px;
    max-width: 1184px;
    margin: 0 auto;
  }
  .pageNav {
    background: rgba(#fff, 0.9);
    justify-content: center;
    display: flex;
    padding: 24px 0;
    max-width: 1184px;
    margin: 0 auto;
    position: sticky;
    top: 0;
    z-index: 4;
    a {
      color: $red;
      border-radius: 4px;
      border: 1px solid $red;
      display: block;
      font-size: 16px;
      padding: 10px 32px 8px;
      margin: 0 16px;
      text-align: center;
      width: 12em;

      @media (hover: hover) {
        transition: 0.1s;
        &:hover {
          background: $red;
          color: #fff;
        }
      }
    }
  }
  .image.en {
    vertical-align: top;
  }
  .cnt {
    @media all and (max-width: 980px) {
      display: block;
      max-width: 480px;
      margin: 0 auto;
    }
    &.en {
      vertical-align: top;
      .bd {
        border-top: $black solid 4px;
        display: block;
        margin-bottom: 40px;
        width: 2em;
      }
      li {
        margin-bottom: 16px;
        span {
          font-weight: bold;
          width: 8em;
        }
      }
    }
  }
  .right-image {
    @media all and (max-width: 980px) {
      display: block;
      & > * {
        display: block;
        width: 100%;
        padding: 0 0 40px;
      }
      .inner {
        display: block;
      }
    }
  }
  .hours {
    div {
      display: flex;
      & + div {
        margin-top: 24px;
      }
      &:last-child {
        margin-top: 16px;
      }
    }
    dt {
      font-weight: 600;
      width: 4em;
    }
    dd {
      flex: 1;
      min-width: 0;
      span {
        display: block;
        padding-left: 1em;
      }
    }
    small {
      color: $black-light;
    }
  }
  .title {
    align-items: center;
    font-size: 32px;
    display: flex;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    &:before {
      content: "";
      border-top: 4px solid $red;
      width: 24px;
      transform: translate(-40px);
      opacity: 0;
      transition: 1.2s;
      margin-left: -56px;
      margin-right: 32px;
    }
    p {
      opacity: 0;
      transition: 1.2s 0.6s;
      transform: translate(-20px);
    }
    &.aos-animate {
      p {
        opacity: 1;
        transform: translate(0px);
      }
      &:before {
        transform: translate(0);
        opacity: 1;
      }
    }
  }
  .informationSns {
    margin: 0 -8px 0 auto;
    font-size: 18px;
    display: flex;
    align-items: center;
    a {
      background: darken($black, 80%);
      display: block;
      margin: 0 8px;
      height: 24px;
      width: 24px;
      @media (hover: hover) {
        img {
          transition: 0.3s;
        }
        &:hover {
          img {
            opacity: 0;
          }
        }
      }
      &.insta {
        mask: url(../images/recruit/icon-instagram.svg) no-repeat center /
          contain;
        background: linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
        );
      }
      &.fb {
        mask: url(../images/recruit/icon-facebook.svg) no-repeat center /
          contain;
        background: #1877f2;
      }
    }
  }
  .qanda {
    max-width: 840px;
    margin: 64px auto 0;
    dt,
    dd {
      display: flex;
      position: relative;
      font-size: 16px;
      line-height: 2;
      &:before {
        display: block;
        font-size: 28px;
        opacity: 0.6;
        line-height: 32px;
        margin-right: 24px;
      }
    }
    dt {
      font-size: 20px;
      &:before {
        content: "Q";
        top: -10px;
        left: 0;
      }
    }
    dd {
      margin-top: 1em;
      &:before {
        content: "A";
        top: -12px;
        left: 1px;
      }
    }
    &Item {
      margin-bottom: 40px;
      padding: 0 30px 40px;
      border-bottom: 1px solid #eee;
    }
  }
}
