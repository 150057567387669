@mixin clearfix {
  & {
    *zoom: 1;
  }
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* -------------- text-overflow -------------- */
@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  width:100%;
  white-space: nowrap;
  line-height:1.2;
}

/* -------------- letterspacing -------------- */
@mixin letterspacing{
  letter-spacing:-.4em;
  *{ letter-spacing:normal; }
}

/* -------------- arrow -------------- */
@mixin arrow($color: $black, $weight: 15px, $direction: top){
  border: $weight solid transparent;
  border-#{$direction}: $weight solid $color;
  content: " ";
  left: 50%;
  height: 0;
  margin-left: -$weight;
  position: absolute;
  pointer-events: none;
  top: 100%;
  width: 0;
}

/* -------------- fullabsolute -------------- */
@mixin fullabsolute {
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

/* -------------- vcenter -------------- */
@mixin vcenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* -------------- hvcenter -------------- */
@mixin hcenter {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* -------------- optim-width -------------- */
@mixin optim-width {
  max-width: 980px;
  margin: 0 auto;
}

@mixin optim-width-large {
  max-width: 1280px;
  margin: 0 auto;
}