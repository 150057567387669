.video-bg {
  display: none;
}
header {
  padding: 40px 40px 0;
}
.lang-toggle {
  color: $gray;
  font-family: $basefont-family;
  font-size: 11px;
  text-align: right;
  a {
    color: $gray;
    padding: 0 4px;

    @media (hover: hover) {
      &:hover {
        color: $black;
      }
    }
    &.active {
      color: $black-light;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
.g-nav {
  background: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 16px 0 0;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: top;
    width: 144px;
    &.active a {
      color: $black;
      pointer-events: none;
      span {
        color: $black-light;
      }
    }
  }
  a {
    display: block;
    line-height: 1.2;
    padding: 16px 0;
    @media (hover: hover) {
      &:hover {
        color: darken($red, 10%);
      }
    }
  }
  span {
    color: $gray;
    display: block;
    font-size: 14px;
    font-weight: normal;
  }
  .mark {
    width: 120px;
    a {
      padding: 10px 0;
    }
    img {
      display: block;
      margin: 0 auto;
      width: 52px;
    }
  }
  &.en {
    a span {
      display: inline-block;
      letter-spacing: 2px;
      font-size: 12px;
      font-weight: bold;
      padding-bottom: 8px;
      position: relative;
      text-transform: uppercase;
      &::after {
        opacity: 0;
        transition: all 0.2s;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 3px;
        background: $gray;
        content: "";
        transform: translateY(10px);
      }
    }
    @media (hover: hover) {
      a:hover span::after {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    a:focus span::after {
      opacity: 1;
      transform: translateY(0px);
    }
    .active a span::after {
      opacity: 1;
      background: $black;
      transform: translateY(0px);
    }
  }
}
#logo {
  a {
    display: block;
    margin: 40px auto 0;
    width: 80px;
  }
  img {
    display: block;
    width: 100%;
  }
}

//調整
#logo img.index {
  display: none;
}
