#index {
  //調整
  header {
    height: 553px;
    padding: 40px;
  }
  .lang-toggle {
    color: rgba(#fff, 0.6);
    a {
      color: rgba(#fff, 0.6);
      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
      &.active {
        color: #fff;
      }
    }
  }
  .g-nav {
    bottom: 0;
    left: 0;
    padding: 40px 0;
    position: absolute;
    width: 100%;
  }
  h1#logo img {
    display: none;
    &.index {
      display: block;
    }
  }
  .video-bg {
    display: block;
  }
  //調整
  .video-bg {
    left: 0;
    height: 400px;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 0;
    .overlay {
      opacity: 0.6;
      background: url(../images/index/bg_pattern1.png) top left repeat;
    }
    img {
      display: none;
    }
  }
  video {
    @include vcenter;
    max-width: 100%;
    height: auto;
  }
  &.sp {
    video {
      display: none;
    }
    .video-bg {
      background: url(../images/index/hero_sp.jpg) center center;
      background-size: cover;
      .overlay {
        opacity: 0.2;
      }
    }
  }
  .greeting {
    text-align: center;
    position: relative;
    margin: 120px auto;
    width: 760px;
    .inner {
      display: inline-block;
      vertical-align: top;
      img {
        display: inline-block;
        height: 400px;
        width: auto;
        vertical-align: top;
      }
      img.title {
        margin-left: 80px;
      }
      p.en {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.6;
        text-align: left;
      }
      h1.en {
        font-family: $enheadfont-family;
        font-size: 60px;
        letter-spacing: 4px;
        margin-top: 40px;
        margin-bottom: 56px;
      }
    }
    .inari,
    .sashimi {
      position: absolute;
      width: 440px;
      z-index: -1;
    }
    .sashimi {
      top: 240px;
      right: -376px;
      @media all and (max-width: 980px) {
        right: -346px;
      }
    }
    .inari {
      top: 80px;
      left: -376px;
      @media all and (max-width: 980px) {
        left: -346px;
      }
    }
  }
  .chief {
    @include clearfix;
    margin: 0 auto;
    max-width: 980px;
    & > .inner {
      margin: 0 -16px;
    }
    article {
      float: left;
      width: 50%;
    }
    .inner {
      padding: 0 16px;
    }
    img {
      display: block;
      width: 100%;
    }
    p {
      padding: 16px 0;
      font-size: 28px;
      span,
      small {
        display: block;
      }
      span {
        font-size: 18px;
      }
      small {
        font-size: 14px;
      }
    }
  }
}
