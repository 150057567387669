#maintenance {
  .container {
    font-size: 16px;
    text-align: center;
    padding: 120px 0;
    b {
      font-size: 20px;
    }
  }
}
