/* -------------- bgelems -------------- */
$grunge_background:url(../images/bg.jpg);


/* -------------- color -------------- */
/*-- white --*/

/*-- glod --*/
$gold:#877146;

/*-- gray --*/
$black:#0e1a26;
$black-light:#353f47;
$gray:#b8bfc1;
$white-dark:#e6ecef;
$white:#f7f8f9;

/*-- color --*/
$red:#af3420;

/*-- sns --*/
$facebook:#3b5998;
$facebook-strong:#2D4D87;
$twitter:#55acee;
$twitter-strong:#3D9DD8;
$google:#dd4b39;
$google-strong:#CC392E;
