@import "~swiper/swiper";
.gift {
  display: flex;
  &-slide {
    flex: 1;
    min-width: 240px;
  }
  &Slide {
    &-main {
      margin-bottom: 24px;
      .swiper-slide {
        background: $white center no-repeat;
        background-size: cover;
        position: relative;
        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }
      }
    }
    &-thumbs .swiper-slide {
      background: $white center no-repeat;
      background-size: cover;
      opacity: 0.4;
      position: relative;
      &:after {
        content: "";
        display: block;
        padding-top: 75%;
      }
      &-thumb-active {
        opacity: 1;
      }
    }
  }
  .cnt {
    font-size: 16px;
    line-height: 2;
    padding-left: 80px;
    width: 560px;
    h2 {
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 40px;
    }
    .price {
      border-bottom: 1px solid $white-dark;
      margin: 40px 0 24px;
      padding-bottom: 16px;
      b {
        font-size: 32px;
        font-weight: 400;
      }
      small {
        font-size: 16px;
        padding-left: 4px;
      }
    }
    table {
      font-family: $basefont-family;
      font-size: 14px;
      text-align: left;
      width: 100%;
      th,
      td {
        padding: 4px 0;
      }
      th {
        font-weight: 400;
        padding-right: 8px;
        opacity: 0.5;
        width: 6em;
        vertical-align: top;
      }
      td {
      }
    }
  }
}
