.specialty {
  section {
    display: flex;
    margin: 0 -16px;
    .image {
      padding: 0 16px;
      width: 45%;
      img {
        width: 100%;
      }
    }
    .cnt {
      line-height: 2;
      flex: 1;
      font-size: 16px;
      padding: 0 16px;
      h2 {
        line-height: 1.6;
        font-weight: 600;
        padding-bottom: 0.5em;
      }
    }
  }
}
